<template>
  <div>
    <canvas id="embeddingScatterChart"></canvas>
  </div>
</template>

<script>

import {Chart, registerables} from "chart.js";

export default {
  name: "EmbeddingScatterChart",
  props: {
    data: Array
  },
  watch: {
    data: function () {
      this.render();
    }
  },
  mounted() {
    Chart.register(...registerables);
  },
  methods: {
    prepareData() {
      let datasets = []
      Object.entries(this.data).forEach(function (entry) {
        entry = entry[1]
        let points = []
        Object.entries(entry.articles).forEach(function (article) {
          article = article[1]
          points.push({x: article.loc[0], y: article.loc[1]});
        });
        datasets.push({
          type: "scatter",
          data: points,
          backgroundColor: '#' + entry.color,
          yAxisID: "y"
        })
      });
      return {
        datasets: datasets
      }
    },
    render() {
      let data = this.prepareData();
      if (this.chart) {
        this.chart.data = data
        this.chart.update()
        return;
      }
      let ctx = document.getElementById('embeddingScatterChart').getContext('2d');
      this.chart = new Chart(ctx, {
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            grid: {
              display: false
            }
          }
        },
      });
    }

  }

}
</script>

<style scoped>

</style>
