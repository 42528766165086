<template>
  <div>
    <canvas id="keywordChartContainer"></canvas>
  </div>
</template>

<script>

import {Chart, registerables} from "chart.js";

export default {
  props: {
    data: Array
  },
  watch: {
    data: function () {
      this.renderChart();
    }
  },
  mounted() {
    Chart.register(...registerables);
  },
  methods: {
    prepareData() {
      let bar_data = [];
      let line_data = [];
      let total_value = 0;
      Object.entries(this.data).forEach(function (entry) {
        entry = entry[1]
        let count = entry.count
        bar_data.push({x: entry.keyword, y: count});
        total_value += count;
      });

      return {
        datasets: [{
          type: "bar",
          data: bar_data,
          label: "Neue Artikel",
          backgroundColor: "#28abf1",
          yAxisID: "y"
        }]
      }
    },
    renderChart() {
      let component = this;
      let data = this.prepareData();
      let ctx = document.getElementById('keywordChartContainer').getContext('2d');
      component.chart = new Chart(ctx, {
        data: data,
        options: {
          layout: {
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              title: {
                text: "#Artikel",
                display: true
              }
            },
            x: {
              ticks: {
                maxRotation: 50,
                minRotation: 20,
                padding: 0,
                autoSkip: false,
                fontSize: 4
              }
            }
          }
        },
      });
    }

  }

}
</script>

<style scoped>

</style>
