<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="query"
          :prepend-inner-icon="icons.mdiMagnify"
          class="app-bar-search flex-grow-0"
          dense
          hide-details
          outlined
          rounded
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn @click="fetch">Compute</v-btn>
      </v-col>
    </v-row>
    <v-progress-linear
      v-show="isLoading"
      class="mb-2 mt-2"
      indeterminate
    ></v-progress-linear>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title>Parameters</v-card-title>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="embeddingField"
                :items="this.embeddingFields"
                label="Embedding Field"></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="type"
                :items="typeOptions"
                filled
                label="Type"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="clusteringAlgorithm"
                :items="clusteringOptions"
                filled
                label="Clustering Algorithm"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="embeddingSize"
                :items="embeddingSizeOptions"
                filled
                label="Embedding size"
              ></v-select>
            </v-col>
            <v-col v-for="param in parameters" cols="12" md="4">
              <v-text-field
                v-model="param.value"
                :label="param.name"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pa-5" style="height: 100%">
          <embedding-scatter-chart :data="topics" style="height: 200px"></embedding-scatter-chart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="topic in topics" cols="12" md="12">
        <v-card class="pa-2 ma-2">
          <v-card-title>
            <div :style="'width: 20px; height: 20px; background-color: #' + topic.color + ';'">
            </div>
            <span class="ms-4">{{ topic.articles.length }} Articles</span>
          </v-card-title>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th>Title</th>
                <th>Publisher</th>
                <th>Published</th>
                <th>Mean Distance to Cluster</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in topic.articles"
                :key="item._id">
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.source }}
                </td>
                <td>
                  {{ moment(item.published).format('ll') }}
                </td>
                <td>
                  {{ item.meanDistance }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline} from '@mdi/js'

// demos
import SourceDistribution from './statistics/SourceDistribution.vue'
import ArticlesOverTime from "@/views/articles/statistics/ArticlesOverTime";
import KeywordDistribution from "@/views/articles/statistics/KeywordDistribution";
import axios from "axios";
import config from "@/utils/config";
import {mdiMagnify, mdiArrowRight} from '@mdi/js'
import EmbeddingScatterChart from "@/views/articles/EmbeddingScatterChart";
import palette from "google-palette"

let moment = require("moment");
moment.locale('de-DE');

export default {
  components: {
    KeywordDistribution,
    SourceDistribution,
    ArticlesOverTime,
    EmbeddingScatterChart
  },
  setup() {
    return {
      embeddingField: null,
      embeddingFields: [],
      moment: moment,
      topics: null,
      query: null,
      clusteringAlgorithm: "dbscan",
      embeddingSize: "complete",
      type: 'transformer',
      isLoading: false,
      parameters: [
        {
          'name': "top_k",
          'value': 100
        },
        {
          'name': "umap_neighbors",
          'value': 3
        },
        {
          'name': "umap_dist_factor",
          'value': 0.2
        },
        {
          'name': "umap_components",
          'value': 2
        },
        {
          'name': "clustering_dist_factor",
          'value': 0.2
        },
        {
          'name': "clustering_min_samples",
          'value': 2
        }
      ],
      typeOptions: ['transformer', 'tfidf', 'combined'],
      clusteringOptions: ['dbscan', 'agglomerative', 'affinity_propagation', 'hdbscan'],
      embeddingSizeOptions: ['complete', 'umap'],
      icons: {
        mdiMagnify
      }
    }
  },
  mounted() {
    axios.get(`${config.SERVER_URL}/embeddings/fields`).then((data) => {
      this.embeddingFields = data.data.embeddingFields;
      this.$forceUpdate();
      this.embeddingField = this.embeddingFields[0];
    });
  },
  methods: {
    fetch() {
      let params = {
        'name': this.query,
        'type': this.type,
        'embedding_field': this.embeddingField,
        "clustering_algorithm": this.clusteringAlgorithm,
        "embedding_size": this.embeddingSize
      }
      for (let i = 0; i < this.parameters.length; i++) {
        let parameter = this.parameters[i]
        console.log(parameter)
        params[parameter.name] = parseFloat(parameter.value)
      }

      this.isLoading = true
      axios.post(`${config.SERVER_URL}/people/topics`, params).then((data) => {
        let topics = []
        let numTopics = data.data.topics.length
        let colors = palette('rainbow', numTopics);
        for (let i = 0; i < numTopics; i++) {
          let topic = data.data.topics[i]
          topics.push({
            'articles': topic,
            'color': colors[i]
          })
        }
        this.topics = topics
        this.isLoading = false
      }).catch((err) => {
        this.isLoading = false
      })
    }
  }
}
</script>
