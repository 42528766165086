<template>
  <div class="position-relative" id="chartContainer">
    <canvas id="eventArticleDistributionChart"></canvas>
  </div>
</template>

<script>
import sources from "../../../assets/source_config.json";
import {Chart, registerables} from "chart.js";

export default {
  name: "SourceDistribution",
  props: {
    articles: Array
  },
  mounted() {
    Chart.register(...registerables);
  },
  watch: {
    articles: function () {
      this.renderArticleDistributionChart();
    }
  },
  methods: {
    prepareData() {
      let article_counts = {};

      Object.entries(sources).forEach(function ([source]) {
        article_counts[source] = 0;
      });

      this.articles.forEach(function (a) {
        article_counts[a.source] = a.count;
      })

      let data = [];
      let labels = [];
      let colors = [];

      Object.entries(article_counts).forEach(function ([source, count]) {
        data.push(count);
        colors.push(sources[source].color);
        labels.push(sources[source].label);
      });
      return {
        labels: labels,
        datasets: [{
          backgroundColor: colors,
          data: data,
          label: "sources"
        }]
      }
    },
    renderArticleDistributionChart() {
      let component = this;
      let data = this.prepareData();
      let ctx = document.getElementById('eventArticleDistributionChart').getContext('2d');

      if (component.chart) {
        component.chart.destroy()
      }

      component.chart = new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "right"
            }
          },
        }
      });
    }

  }
}
</script>

<style scoped>

#chartContainer {
  height: 200px;
}

</style>
